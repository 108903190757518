import { computed, ref } from "vue";
import { projectFirestore, projectFunctions } from "../../../../../../firebase/config";
import Swal from 'sweetalert2'
import { projectDataBase } from '../../../../../../firebase/config'
import store from "../../../../../../store";
const items = ref([])
const promos = ref([])
const categories = ref([])
const branches = ref([])
const suppliers = ref([])
const is_catalog_loaded = ref(false)

const user = ref(computed(() => {
    return store.state.user
}))

function dateStringToISODateObject(dateString) {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    return new Date(year, month - 1, day).toISOString();
}

const get_all_deals_from_db = async () => {
    const docs = await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
        .collection('Deals').get()
    const res = docs.docs.map(doc => doc.data())
    return res
}

const orgenize_categories = (items) => {
    const set = new Set()
    for (const item of items) {
        if (item.products?.length > 0) {
            set.add(item.products[0].Department)
        }
    }

    categories.value = ['הכל', ...set]
    console.log(categories.value);
}

const orgenize_suppliers = (items) => {
    const set = new Set()
    for (const item of items) {
        if (item.SupplierName) {
            set.add(item.SupplierName)
        }
    }

    suppliers.value = ['הכל', ...set]
    console.log(suppliers.value);
}

const get_promos_from_comax_api = async () => {
    promos.value = [];
    items.value = [];
    is_catalog_loaded.value = false
    try {
        promos.value = await (await projectFunctions.httpsCallable("getComaxPromos", { timeout: 540000 })({
            branche:user.value.branche
        })).data
        if (promos.value.length) {
            console.log('promos:',promos.value);
            items.value = await (await projectFunctions.httpsCallable("getComaxPromosItems", { timeout: 540000 })({
                branche:user.value.branche
            })).data
            console.log('promos items:',items.value);
            orgenize_branches(promos.value)
            orgenize_categories(promos.value)
            orgenize_suppliers(items.value)
        }else{
            throw new Error('No promotions found!')
        }
      
    } catch (error) {
        console.log(error);
        Swal.fire(error.message);
    }
    //await get_all_items_from_comax_api();
}

const get_non_promos_items = async () => {
    try {
        let non_promos_items = []
        non_promos_items = await (await projectFunctions.httpsCallable("getNonComaxPromosItems", { timeout: 540000 })({
            branche:user.value.branche || 'Unknow'
        })).data
        if (non_promos_items.length) {
            console.log('non_promos_items:',non_promos_items);
            items.value = [...items.value,...non_promos_items]
            console.log('promos items:',items.value);
            orgenize_suppliers(items.value)
            is_catalog_loaded.value = true
        }else{
            throw new Error('No Non Promos Items Found!')
        }
      
    } catch (error) {
        console.log(error);
        Swal.fire(error.message);
    }
    //await get_all_items_from_comax_api();
}



const get_all_items_from_comax_api = async () => {
    console.log('get_all_items_from_comax_api');
    try {
        items.value = []
        const results = await projectFunctions.httpsCallable("getComaxItemss", { timeout: 540000 })();
        if (results && results.data) {
            console.log('got item data');

            for (let i = 0; i < results.data.length; i++) {
                if (results.data[i]) {

                    items.value.push({
                        ArchiveDate: results.data[i]?.ArchiveDate[0],
                        BlockPurchaseDate: results.data[i]?.BlockPurchaseDate[0],
                        BlockSalesDate: results.data[i]?.BlockSalesDate[0],
                        Department: results.data[i]?.Department[0],
                        DepartmentCode: results.data[i]?.DepartmentCode[0],
                        Group: results.data[i]?.Group[0],
                        GroupCode: results.data[i]?.GroupCode[0],
                        Name: results.data[i]?.Name[0],
                        SupplierID: results.data[i]?.SupplierID[0],
                        SupplierName: results.data[i]?.SupplierName[0],
                        barcode: results.data[i]?.Barcode[0],
                        code: results.data[i]?.ID[0],
                        price: results.data[i]?.Price[0],
                        weight: results.data[i]?.Weight[0],
                        country: results.data[i]?.ManufacturingCountry[0],
                    })
                }
            }
            items.value = items.value.filter(itm => !itm.BlockSalesDate)

            attach_items();
        }
    } catch (error) {
        console.log(error);
        Swal.fire(error.message)
    }
}

const get_all_items_from_comax_per_suppliers = async () => {
    try {

        /* getComaxDataFromPrivateServer */
        items.value = []

        items.value = await (await projectFunctions.httpsCallable("getComaxDataFromPrivateServer", { timeout: 540000 })({
            branche:user.value.branche || 'Unknow'
        })).data
        console.log('items:',items.value);
        projectFunctions.httpsCallable("restartTunnleServer", { timeout: 540000 })()
        // const docs = await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
        //     .collection('Suppliers').get()

        // const suppliers_id = docs.docs.map(doc => doc.data().id)

      

        // const promises = suppliers_id.map(id => {
        //     return projectFunctions.httpsCallable("getComaxDataBysupplierNum", { timeout: 540000 })({
        //         supplier_num: id,
        //         branche:user.value.branche || 'Branche:Unknow'
        //     }).catch(error => {
        //         // Handle or log the error as needed, return an error indicator or null
        //         console.error("Error with supplier", id, error);
        //         return { error: true, id: id, details: error }; // Example error format
        //     });
        // });

        // const f_data = await Promise.all(promises);

        // // f_data will now contain success results and error objects
        // // You can filter or process them as needed
        // const successes = f_data.filter(result => !result.error);
        // const errors = f_data.filter(result => result.error);
        // console.log('errors_list:', errors)
        // // Now you have lists of successes and errors to handle as needed

        // for (const _items of successes) {
        //     for (const item of _items.data) {
        //         if(item.Name){
        //             items.value.push({
        //                 ArchiveDate: item?.ArchiveDate[0],
        //                 BlockPurchaseDate: item?.BlockPurchaseDate[0],
        //                 BlockSalesDate: item?.BlockSalesDate[0],
        //                 Department: item?.Department[0],
        //                 DepartmentCode: item?.DepartmentCode[0],
        //                 Group: item?.Group[0],
        //                 GroupCode: item?.GroupCode[0],
        //                 Name: item?.Name[0],
        //                 SupplierID: item?.SupplierID[0],
        //                 SupplierName: item?.SupplierName[0],
        //                 barcode: item?.Barcode[0],
        //                 code: item?.ID[0],
        //                 price: item?.Price[0],
        //                 weight: item?.Weight[0],
        //                 country: item?.ManufacturingCountry[0],
        //             })
        //         }
        //     }
        // }

        // console.log('Restart Tunnle Server');

        // projectFunctions.httpsCallable("restartTunnleServer", { timeout: 540000 })()

        /* ============================================================================================= */

        // items.value = successes.reduce((accumulator, currentArray) => {
        //     return accumulator.concat(currentArray.data);
        // }, []);

        // items.value = items.value.filter(itm => !itm.BlockSalesDate)

        // // console.log(items.value)

        // const countries = items.value.filter(itm => itm.country)
        // console.log('countries',countries)
        // localStorage.setItem('promosPromo', JSON.stringify(promos.value));
        attach_items();

    } catch (err) {
        console.log(err);
        Swal.fire(err.message)
    }
}

const orgenize_branches = (promos) => {
    let temp = []
    promos.forEach(promo => {
        if (promo.Stores?.length > 0 && promo.Stores[0] != '') {
            temp = temp.concat(promo.Stores)
        }
    });
    const remove_duplicates = new Set(temp)
    branches.value = ['הכל', ...remove_duplicates]
}

const attach_items = () => {
    for (let i = 0; i < promos.value.length; i++) {
        let promo = promos.value[i];
        if (!promo.prodcuts) {
            promo.products = []
        }
        for (let j = 0; j < promo.Items.length; j++) {
            let promo_item_barcode = promo.Items[j];
            let inx = items.value.findIndex(itm => is_promo_match_to_item(itm.barcode,itm.code, promo_item_barcode))
            if (inx != -1) {
                items.value[inx].amount = promo.amount
                promo.products.push(items.value[inx]);
            }
        }
    }
    orgenize_branches(promos.value)
    orgenize_categories(promos.value)
    orgenize_suppliers(items.value)
}

const items_and_promos_init = async () => {
    const storedItems = localStorage.getItem('itemsPromo');
    const storedPromos = localStorage.getItem('promosPromo');
    if (storedItems && storedPromos) {
        items.value = JSON.parse(storedItems)
        promos.value = JSON.parse(storedPromos)
        attach_items();
        orgenize_suppliers(items.value)
    } else {
        get_promos_from_comax_api()
    }
}

const items_and_promos_data_from_API = async () => {
    try {
        await get_promos_from_comax_api()
    } catch (err) {
        throw new Error(err)
    }
}

const items_and_promos_data_from_catch = async () => {
    try {
        // let storedItems = false
        // const snapshot = await projectDataBase.ref(`ComaxData-${user_uid.value}`).once('value');
        // if (snapshot.exists()) storedItems = true

        // const storedItems = localStorage.getItem('itemsPromo');
        const storedPromos = localStorage.getItem('promosPromo');
        if (storedPromos) {
            // items.value = JSON.parse(storedItems)
            // items.value = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
            promos.value = JSON.parse(storedPromos)
            await get_all_items_from_comax_per_suppliers()


        } else {
            throw new Error('לא קיימים נתונים קודמים!')
        }
    } catch (err) {
        items.value = []
        promos.value = []
        throw new Error(err)
    }
}

const check_if_deal_exist = async (deal_uid) => {
    const doc = await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
        .collection('Deals').doc(deal_uid).get()
    return doc.exists
}

const store_new_deal_in_db = async (deal) => {
    await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
        .collection('Deals').doc(deal.deal_uid)
        .set(deal, { merge: true })
}

const delete_deal_from_db = async (deal_uid) => {
    await projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
        .collection('Deals').doc(deal_uid).delete()
}

const get_item_by_barcodes = async (barcodes) => {
    const promises = []
    for (const barcode of barcodes) {
        promises.push(projectFirestore.collection('Applications').doc('DWwXD62stBclxW1krpZ9')
            .collection('Items').doc(barcode).get())
    }
    const docs = await Promise.all(promises)
    const res = docs.map(doc => doc.data())
    return res
}

const translate_hebrew_to_russian = async (hebrew_sentence) => {
    try {
        const translate_result = await projectFunctions.httpsCallable("translate_Hebrew_ToRussian", { timeout: 540000 })({ hebrew_sentence });
        if (translate_result.data.includes('valid') || translate_result.data.includes('please provide')
            || translate_result.data.includes('translation')
            || translate_result.data.includes('Hebrew')) {
            throw new Error('אין אפשרות לתרגם את הטקסט שהוזן!')
        }
        console.log(translate_result.data);
        return translate_result.data
    } catch (err) {
        console.log(err);
        throw new Error(err)
    }
}

function is_promo_match_to_item(item_barcode, item_code, promo_item_barcode) {
    if (typeof item_barcode !== 'string' || typeof item_code !== 'string' || typeof promo_item_barcode !== 'string') {
        return false;
    }

    if (!item_code || !item_barcode || !promo_item_barcode) return false;
    
    const promoLength = promo_item_barcode.length;
    return item_code.slice(-promoLength) === promo_item_barcode || item_barcode.slice(-promoLength) === promo_item_barcode;
}


export {
    get_all_deals_from_db,
    store_new_deal_in_db,
    check_if_deal_exist,
    delete_deal_from_db,
    get_item_by_barcodes,
    get_promos_from_comax_api,
    items_and_promos_init,
    items_and_promos_data_from_catch,
    items_and_promos_data_from_API,
    translate_hebrew_to_russian,
    promos,
    items,
    categories,
    branches,
    suppliers,
    is_promo_match_to_item,
    get_non_promos_items,
    is_catalog_loaded,
}